<template>
  <History :datas="[1, 'viewGdsHistory', 'exportGdsHistory']" />
</template>
<script>
import History from '@/components/threshold/History.vue'
export default {
  components: {
    History,
  },
  data() {
    return {}
  },
  mounted() {},
}
</script>
